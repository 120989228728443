import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import JosefinSans from "../fonts/JosefinSans-VariableFont_wght.woff2";
import Raleway from "../fonts/Raleway-VariableFont_wght.woff2";
import RalewayItalic from "../fonts/Raleway-Italic-VariableFont_wght.woff2";

function SEO({ description, lang, meta, title }) {
  const { prismicMetadata } = useStaticQuery(graphql`
    query DefaultSEOQuery {
      prismicMetadata {
        _previewable
        data {
          site_name
          default_page_description
        }
      }
    }
  `);

  const site = {
    siteName: prismicMetadata.data.site_name,
    defaultDescription: prismicMetadata.data.default_page_description,
  };

  const metaDescription = description || site.defaultDescription;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(meta)}
      title={title}
      titleTemplate={`%s | ${site.siteName}`}
    >
      <link rel="preload" href={JosefinSans} as="font" type="font/woff2" crossOrigin="anonymous" />
      <link rel="preload" href={Raleway} as="font" type="font/woff2" crossOrigin="anonymous" />
      <link
        rel="preload"
        href={RalewayItalic}
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `nl`,
  meta: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired,
};

export default SEO;
