import React from "react";
import * as styles from "./section.module.scss";

function Section({ bg, borderColor, children }) {
  let bgClass = "";
  switch (bg) {
    case "blue":
      bgClass = styles.blue;
      break;
    case "green":
      bgClass = styles.green;
      break;
    case "gold":
      bgClass = styles.gold;
      break;
  }
  let borderColorClass = "";
  switch (borderColor) {
    case "blue":
      borderColorClass = styles.borderBlue;
      break;
  }
  return (
    <section className={`${styles.section} ${bgClass} ${borderColorClass}`}>{children}</section>
  );
}

export default Section;
