import PropTypes from "prop-types";

import React from "react";
import * as styles from "./content-column.module.scss";

function ContentColumn({ children }) {
  return <div className={styles.column}>{children}</div>;
}

ContentColumn.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentColumn;
